.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--close {
  opacity: 0;
}

.Spocket-Modal .Spocket-Modal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1031;
  overflow: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Spocket-Modal__Overlay--clean {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1031;
  overflow: auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.Spocket-Modal__Overlay__light {
  background-color: rgba(255, 255, 255, 0.96);
}

/* .Spocket-Modal__Overlay__product--card {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
} */

.Spocket-Modal__Overlay__dark {
  background-color: rgba(8, 31, 64, 0.45) !important;
  backdrop-filter: blur(6px);
}

.Spocket-Modal .Spocket-Modal__Content {
  padding: 24px;
  margin: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(34, 41, 57, 0.15);
  background-color: #fff;
  outline: none;
  display: inline-block;
}

.Spocket-Modal .Spocket-Modal__Content--clean {
  outline: none;
}

@media (max-width: 768px) {
  .Spocket-Modal__Content {
    width: 95%;
  }
}
